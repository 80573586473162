window.kentico = window.kentico || {}, window.kentico.updatableFormHelper = function () {
	function e(e, n) {
		if (e) {
			var i = e.getAttribute("data-ktc-ajax-update");
			if (i) {
				$(e).find("input[type='submit']").attr("onclick", "return false;"), e.updating = !0;
				var a = new FormData(e);
				a.append("kentico_update_form", "true");
				var r = n || document.activeElement;
				t(e, a, (function (e) {
					if (!e.target.response.data) {
						var t = selectionEnd = null;
						!r || "text" !== r.type && "search" !== r.type && "password" !== r.type && "tel" !== r.type && "url" !== r.type || (t = r.selectionStart, selectionEnd = r.selectionEnd);
						var n = $(window).scrollTop();
						if ($(i).replaceWith(e.target.responseText), $(window).scrollTop(n), r.id) {
							var a = document.getElementById(r.id);
							a && (a.focus(), function (e, t, n) {
								if (null === t && null === n) return;
								e.setSelectionRange && e.setSelectionRange(t, n)
							}(a, t, selectionEnd))
						}
					}
				}))
			}
		}
	}

	function t(e, t, n) {
		var i = new XMLHttpRequest;
		i.addEventListener("load", n), i.open("POST", e.action), i.send(t)
	}

	function n(e, t, n) {
		var i;
		return function () {
			var a = this,
				r = arguments,
				o = function () {
					i = null, n || e.apply(a, r)
				},
				s = n && !i;
			clearTimeout(i), i = setTimeout(o, t || 200), s && e.apply(a, r)
		}
	}
	return {
		registerEventListeners: function (t) {
			if (!(t && t.formId && t.targetAttributeName && t.unobservedAttributeName)) throw new Error("Invalid configuration passed.");
			var a = ["email", "number", "password", "search", "tel", "text", "time"],
				r = document.getElementById(t.formId);
			if (r && r.getAttribute(t.targetAttributeName))
				for (i = 0; i < r.length; i++) {
					var o = r.elements[i];
					if (!o.hasAttribute(t.unobservedAttributeName) && "submit" !== o.type)("INPUT" === o.tagName && -1 !== a.indexOf(o.type) || "TEXTAREA" === o.tagName) && (o.previousValue = o.value, o.addEventListener("keyup", n((function (t) {
						setTimeout((function () {
							r.updating || t.target.previousValue === t.target.value || (r.keyupUpdate = !0, e(r, t.target))
						}), 0)
					}), 800)), o.addEventListener("blur", (function (t) {
						setTimeout((function () {
							r.updating || t.target.previousValue === t.target.value || e(r, t.relatedTarget)
						}), 0)
					}))), o.addEventListener("change", (function (t) {
						setTimeout((function () {
							r.updating || e(r)
						}), 0)
					}))
				}
		},
		updateForm: e,
		submitForm: function (e) {
			e.preventDefault();
			var n = e.target,
				i = new FormData(n);
			t(n, i, (function (e) {
				if (-1 === e.target.getResponseHeader("Content-Type").indexOf("application/json")) {
					var t = $(window).scrollTop(),
						i = n.getAttribute("data-ktc-ajax-update");
					$(i).replaceWith(e.target.response), $(window).scrollTop(t);
					var event = new CustomEvent('kentico-form-submit');
					window.dispatchEvent(event);
				} else {
					var a = JSON.parse(e.target.response);
					location.href = a.redirectTo
				}
			}))
		}
	}
}();